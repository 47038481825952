export default function X() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_286_696)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.51609 0H15.4839C17.9678 0 20 2.04164 20 4.53701V15.463C20 17.9584 17.9678 20 15.4839 20H4.51609C2.03223 20 0 17.9584 0 15.463V4.53701C0 2.04164 2.03223 0 4.51609 0ZM12.6461 5.82195H14.057L10.9745 9.36159L14.6009 14.1781H11.7613L9.53738 11.2569L6.99266 14.1781H5.5807L8.87785 10.3922L5.39902 5.82195H8.31055L10.3209 8.49207L12.6461 5.82195ZM12.1508 13.3296H12.9327L7.88578 6.62593H7.0468L12.1508 13.3296Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_286_696">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
