export default function Mail() {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="min-h-4 min-w-5"
    >
      <path
        d="M18.9143 0.283648L17.3786 1.45455L10.4286 6.76352C10.1786 6.95988 9.8215 6.95261 9.57149 6.76352L2.62154 1.45455L1.08582 0.283648C1.4001 0.10183 1.7644 0 2.14296 0H17.8572C18.2358 0 18.6 0.101818 18.9143 0.283648ZM19.85 1.3891L18.5714 2.36365L11.2857 7.92723C10.9072 8.21814 10.45 8.36359 10 8.36359C9.54287 8.36359 9.09286 8.21814 8.7143 7.92723L1.42858 2.36365L0.15 1.3891C0.05 1.63638 0 1.90548 0 2.18182V13.8182C0 15.0182 0.964297 16 2.14286 16H17.8571C19.0357 16 20 15.0182 20 13.8182V2.18182C20 1.90545 19.95 1.63635 19.85 1.3891Z"
        fill="white"
      />
    </svg>
  );
}
