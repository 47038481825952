import NotFound from "~/svgs/NotFoundSvg";
import Footer from "./Footer";
import Nav from "./Nav";
import { useNavigate } from "@remix-run/react";
import { useTranslation } from "react-i18next";

export default function NotFoundSection() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <div className="flex min-h-screen flex-col items-stretch">
      <Nav />
      <div className="flex flex-1 flex-col items-center gap-8 py-5">
        <NotFound />

        <button className="btn mt-8" onClick={() => navigate(-1)}>
          Go Back
        </button>
      </div>
      <Footer />
    </div>
  );
}
