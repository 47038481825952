export default function Facebook() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9841 0H5C2.25397 0 0 2.25397 0 5.01587V15C0 17.746 2.25397 20 5 20H14.9841C17.746 20 20 17.746 20 14.9841V5.01587C20 2.25397 17.746 0 14.9841 0ZM12.6825 10H10.7937V16.1905H8.4127V10H7.14286V7.46032H8.25397V6.38095C8.25397 5.36508 8.7619 3.7619 10.9048 3.7619H12.8571V5.87302H11.4762C11.254 5.87302 10.9524 6.01587 10.9524 6.50794V7.46032H12.9048L12.6825 10Z"
        fill="white"
      />
    </svg>
  );
}
