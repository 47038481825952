export function LogoAr({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      viewBox="0 0 657.63 199.34"
      className="h-14 w-48"
    >
      <defs>
        <style>
          {
            ".cls-1{fill:#5965cd}.cls-2{fill:#7b88df}.cls-4{fill:#0a1454}.cls-5{fill:#3643ba}"
          }
        </style>
      </defs>
      <path
        d="M584.6 162.51c3.58 3.37-24.22.52-23.29.3-1.45-.37-.34-1.61.54-2 2.27-1.27 4.55-2.58 6.86-3.92 1.42-.87 3.18-1.3 4.77-.63.52.2 1.03.48 1.51.77 3.19 1.97 6.44 3.63 9.58 5.44l.04.03ZM657.27 93.29c-.25 1.98-.43 4.32-.95 6.3-.72 2.62-2.26 2.69-4.43 1.29-1.14-.69-2.64-1.32-3.2-2.6-.39-1.05.51-2.06 1.24-2.75 1.2-1.1 2.38-2.17 3.57-3.26 1.39-1.45 3.62-1.35 3.76.95v.06ZM507.77 82.71c-1.66.98-3.58 1.51-5.35 2.29-1.09.43-2.17.86-3.26 1.28-1.25.39-2.66 1.33-3.94 1.01-.74-.21-1.06-.98-1.04-1.66-.28-4.99.86-28.37 5.17-25.58 1.13.85 1.38 2.58 1.92 3.87.45 1.25.92 2.5 1.41 3.77.78 2.03 1.62 4.08 2.51 6.15.87 2.02 1.79 4.05 2.76 6.1.43.87.82 2.05-.11 2.73l-.05.04Z"
        className="cls-1"
      />
      <path
        d="M572.09 48.68c-4.25 2.76-8.69 5.29-13.08 7.86-1.77 1.12-4.01 1.91-6.01.93-1.33-.57-2.27-1.81-2.95-3.06-6.69-12.33-12.58-25.27-16.34-38.89-.34-2.32 1.05-4.43 3.07-5.47 4.69-2.6 9.66-4.71 14.85-6.34 3.71-1.32 6.8.39 7.78 4.18 3.61 11.68 8.51 22.95 14.51 34.08 1.16 2.33.4 5.23-1.77 6.67l-.05.04Z"
        className="cls-2"
      />
      <path
        d="M617.09 10.98c5.06 3.85-1.68 7.86-4.73 10.13-3.96 2.96-7.94 6.01-12.07 8.76-2.92 1.9-6.76 6.2-10.49 5.8-1.53-.21-2.61-1.81-3.37-3.1-3.45-5.69-6.64-11.58-9.52-17.62-.55-1.18-.97-2.2-1.58-3.43-1.25-2.76-3.41-6.04-2.68-9.08.6-2.06 3.43-2.53 5.35-2.42 13.72.37 27.32 3.89 39.03 10.92l.06.05Z"
        style={{
          fill: "#9eaaf2",
        }}
      />
      <path
        d="M637.5 108.37c-20.35 18.65-29.81 13.54-2.24 27.63 5.02 2.17 12.76-14.59 15.17-18.86 1.85-3.47-1.47-4.39-3.98-5.69-1.42-.72-2.85-1.48-4.26-2.24-1.44-.79-3.11-1.89-4.65-.87l-.05.03ZM498.3 107.78c1.53 4.63 3.91 10.32 6.37 14.88 2.09 3.61 3.99 6.39 6.29 6.14.59-.1 1.33-.45 1.95-.72 5.11-2.36 10.29-4.61 15.27-7.24 1.91-1.14 1.91-3.24.58-4.85-3.52-4.54-6.82-9.18-9.74-13.81-1.76-3.21-4.75-4.5-8.14-2.81-3.22 1.51-6.43 3-9.67 4.45-1.72.66-3.31 1.91-2.91 3.91v.05ZM646.01 38.81c-3.6-5.4-12.93-20.77-19.12-16.4-9.3 8.09-18.86 15.96-28.6 23.57-1.63 1.6-.83 3.24.53 4.67 4.08 5.11 11.25 15.15 15.13 16.28 1 .35 1.87-.59 2.73-1.23 9.65-7.46 19.09-15.24 28.16-23.34 1.01-.82 1.78-2.29 1.2-3.5l-.02-.05Z"
        className="cls-2"
      />
      <path
        d="M657.5 70.7c.84 5.75-2.61 10.58-6.68 14.23-3.08 2.78-6.26 5.35-9.23 8.18-9.34 8.15-18.87 16.15-28.84 23.52-3.19 2.15-6.97 1.44-9.73-1.03-7.01-5.56-15.03-12.1-21.13-18.58-1.16-1.19-2.52-2.67-4.22-2.89-1.42-.18-2.79.74-3.96 1.47-8.04 5-16.18 9.8-24.47 14.34-1.07.58-2.21 1.11-3.41 1.33-2.7.51-5.48-.39-7.39-2.37-14.57-16.97-25.76-36.52-33.79-57.41-.68-1.7-1.2-3.44-1.02-5.25.65-7.78 9.46-16.52 14.53-22.12 1.3-1.48 3.69-1.96 5.13-.4.71.73 1.05 1.77 1.33 2.75 1.32 4.61 2.87 9.37 4.68 14.2 4.5 11.62 10.14 24.06 17.7 34.64 2.12 2.22 5.4 2.08 7.83.42 5.08-3.09 8.46-5.3 12.89-8.25 20.68-14.7 15.2-8.32 32.16 9.15a179.8 179.8 0 0 0 5.98 6.19c.92.9 2.16 1.65 3.47 1.49 1.22-.13 2.13-.81 2.99-1.54 7.36-6.3 21.92-19.16 28.11-25.05.68-.64 1.23-1.09 1.99-1.83 2.2-2.16 5.27-6.31 7.7-6.26 1.44.02 2.28 1.68 2.75 2.91 2.21 5.75 3.84 11.87 4.65 18.1v.07Z"
        className="cls-5"
      />
      <path
        d="M625.28 147.83c-5.95 4.13-15.38 10.76-21.78 11.05-1.2.08-2.37-.35-3.47-.94-14.8-7.75-30.33-17.71-42.84-29.85-1.93-2.85-.44-5.94 2.39-7.43 4.37-2.72 8.68-5.51 12.96-8.37 1.25-.87 2.76-1.58 4.32-1.31.89.13 1.71.55 2.45 1.11 13.57 11.47 29.06 20.83 44.68 27.48 3.39 1.4 4.24 5.84 1.34 8.22l-.05.04Z"
        className="cls-1"
      />
      <path
        d="M558.42 152.64c-3.68 1.84-8.16 5.94-12.07 5.7-1.15-.15-1.94-.69-3.03-1.15-.8-.36-1.72-.79-2.71-1.26-7.97-3.9-23.2-11.48-13.47-16.73 4.34-2.17 8.47-4.75 12.85-6.81 2.02-.78 3.95.42 5.31 1.87 4.11 3.94 8.29 7.56 12.51 10.89 2.81 1.91 3.7 5.28.67 7.46l-.05.04Z"
        className="cls-2"
      />
      <path
        d="M219.19 172.35c-4.7-.04-8.04-.8-10.03-2.27-3.61-2.6-5.44-6.08-5.48-10.43 0-4.43 1.65-7.97 4.95-10.61 2.29-1.75 4.9-2.62 7.81-2.62 3.58 0 6.68.89 9.33 2.68v19.47h4.2v3.79h-10.78Zm-11.75-32.72c-.52-.52-.79-1.16-.79-1.89s.26-1.38.79-1.92c.52-.54 1.16-.82 1.89-.82s1.37.26 1.89.79c.52.52.79 1.18.79 1.95s-.26 1.37-.79 1.89-1.16.79-1.89.79-1.37-.26-1.89-.79Zm13.96 11.16c-1.83-.7-3.58-1.05-5.25-1.05-2.1 0-3.85.72-5.25 2.16-1.94 2.1-2.91 4.57-2.91 7.4 0 6.14 3.5 9.23 10.49 9.27h2.91v-17.78Zm-4.95-11.16c-.54-.52-.82-1.16-.82-1.89s.27-1.38.82-1.92 1.19-.82 1.92-.82c.7 0 1.31.26 1.84.79.52.52.79 1.18.79 1.95s-.26 1.37-.79 1.89c-.52.52-1.14.79-1.84.79-.74 0-1.38-.26-1.92-.79Z"
        className="cls-4"
      />
      <path
        d="M229.1 172.35v-3.79h6.06c.35-1.36.52-3.34.52-5.95v-11.6h4.02v7.11c1.59-2.99 3.92-5.52 6.99-7.58 2.56-1.71 5.23-2.56 7.99-2.56 4.12 0 7.5 1.81 10.14 5.42 2.1 2.99 3.15 6.99 3.15 12.01v6.94h-38.88Zm34.56-7.16c0-4-.5-6.82-1.52-8.45-2.06-3.15-4.68-4.74-7.87-4.78-3.03 0-6.02 1.67-8.98 5.01-2.99 3.23-5.03 7.09-6.12 11.6h24.48v-3.38Zm-20.25-23.82a2.6 2.6 0 0 1-.79-1.89c0-.78.26-1.43.79-1.95s1.16-.79 1.89-.79 1.37.26 1.89.79c.52.52.79 1.18.79 1.95s-.26 1.37-.79 1.89c-.52.52-1.16.79-1.89.79s-1.37-.26-1.89-.79ZM280.86 184.54v-3.85h2.56c4.7 0 7.19-2.78 7.46-8.34h-2.74c-4.7-.04-8.02-.8-9.97-2.27-3.65-2.6-5.5-6.08-5.54-10.43 0-4.43 1.67-7.97 5.01-10.61 2.22-1.75 4.8-2.62 7.75-2.62 3.58 0 6.68.91 9.33 2.74v19.41h4.2v3.79h-4.2c0 8.08-3.65 12.14-10.96 12.18h-2.91Zm9.56-33.75c-1.83-.7-3.59-1.05-5.3-1.05-2.06 0-3.81.72-5.25 2.16-1.94 2.06-2.91 4.53-2.91 7.4 0 6.14 3.52 9.23 10.55 9.27h2.91v-17.78Z"
        className="cls-4"
      />
      <path
        d="M305.28 169.03c-1.05 2.21-2.99 3.32-5.83 3.32h-1.4v-3.79h.87c3.19-.04 4.82-2.33 4.9-6.88v-.99c0-4.2.92-7.42 2.77-9.68 1.85-2.25 4.58-3.38 8.19-3.38s6.33 1.19 8.39 3.56c2.06 2.37 3.09 5.75 3.09 10.14 0 3.3-.56 6.02-1.69 8.16-1.79 3.46-4.84 5.23-9.15 5.3-4.55 0-7.93-1.92-10.14-5.77Zm16.79-7.05c0-7.23-2.35-10.84-7.05-10.84-4.51.08-6.76 3.42-6.76 10.03s2.35 10.14 7.05 10.14 6.76-3.11 6.76-9.33ZM344.91 174.28v-5.71h8.16l-6.94-13.64c2.06-3.58 5.09-6.12 9.09-7.64 1.83-.62 3.52-.93 5.07-.93 1.2 0 2.43.19 3.67.58 3.34 1.09 5.77 3.19 7.29 6.3.85 1.83 1.28 3.65 1.28 5.48 0 3.96-1.48 7.25-4.43 9.85h8.69v3.79h-27.8v1.28c0 4.86 2.56 7.29 7.69 7.29 3.26-.04 5.81-.33 7.64-.87v3.79c-1.98.39-4.76.62-8.34.7-7.31 0-11-3.42-11.07-10.26Zm20.23-8.4c2.06-2.02 3.09-4.35 3.09-6.99-.08-4.16-1.9-6.84-5.48-8.04-.85-.31-1.67-.47-2.45-.47-4.04 0-7.19 1.61-9.44 4.84l6.94 13.35c3.42-.27 5.87-1.17 7.34-2.68Z"
        className="cls-4"
      />
      <path
        d="M375.92 172.35v-3.79h6v-19h4.31v19h6v3.79h-16.32Zm6.09-30.98a2.6 2.6 0 0 1-.79-1.89c0-.78.26-1.43.79-1.95s1.16-.79 1.89-.79 1.37.26 1.89.79c.52.52.79 1.18.79 1.95s-.26 1.37-.79 1.89c-.52.52-1.16.79-1.89.79s-1.37-.26-1.89-.79Z"
        className="cls-4"
      />
      <path
        d="M391.37 172.35v-3.79h6.06c.35-1.36.52-3.34.52-5.95v-11.6h4.02v7.11c1.59-2.99 3.92-5.52 6.99-7.58 2.56-1.71 5.23-2.56 7.99-2.56 4.12 0 7.5 1.81 10.14 5.42 2.1 2.99 3.15 6.99 3.15 12.01v6.94h-38.88Zm34.56-7.16c0-4-.5-6.82-1.52-8.45-2.06-3.15-4.68-4.74-7.87-4.78-3.03 0-6.02 1.67-8.98 5.01-2.99 3.23-5.03 7.09-6.12 11.6h24.48v-3.38ZM433.45 185.7a8.16 8.16 0 0 1 2.16-1.28c-1.2-.82-1.81-1.9-1.81-3.26 0-1.52.62-2.64 1.87-3.38.66-.39 1.4-.58 2.21-.58.93 0 1.75.17 2.45.52l-.35 1.98c-.51-.16-.89-.23-1.17-.23-1.4 0-2.1.64-2.1 1.92 0 1.48 1.34 2.21 4.02 2.21h.41l-.06 2.04c-2.56.39-4.99 1.15-7.29 2.27l-.35-2.21Zm1.46-13.35v-36.49h4.31v36.49h-4.31Z"
        className="cls-4"
      />
      <path
        d="M325.05 49.52c-.51 9.03.51 15.17-6.42 18.78-6.28 2.35-18.22 1.28-25.81.34V24.76h-24.29c-.62 9.55 1.47 64.4-1.41 72.18-4.1 11.28-23.72 6.03-33.75 5.58v19.49c30.47 4.81 59.68 5.3 59.4-33.75 57.86 8.69 57.69-14.34 56.57-63.5h-24.29v24.76Z"
        className="cls-5"
      />
      <path
        d="M253.98 72.61c4.54-10.88 2.8-35.22 3.11-47.85H232.8v24.76c-.04 7.78-.17 15.35-8.27 17.65-10.47 3.02-28.36 2.8-34.22-4.66-9.13-8.66-4.75-40.84-5.72-53.12H160.3c1.29 18.33-6.3 61.38 11.86 71.83 23.63 13.72 74.92 14.77 81.81-8.62M141.48 27.93c-11.98-8.12-37.31-8.21-48.54 2.13-7.24 6.51-8.25 18.65-7.82 28.33-.13 4.01-1.13 9.36-2.7 12.03-4.05 8.66-18.71 8.28-22.75.24-4.95-7.91-2.59-25.02-3.12-34.15H31.99c-1.8 44.62 3.08 56.83 50.4 53.68 14.98-1.59 23.4-7.72 26.24-21.8 1.93-7.49-1.22-20.55 5.15-25.46 2.52-2.32 6.69-2.43 9.27-.15 7.84 5.54 3.08 39.24 4.31 47.74h24.56c-1.39-16.22 5.43-52.91-10.43-62.59M320.37 97.83h20.46v20.46h-20.46zM198.32 97.83h20.46v20.46h-20.46zM298.41 97.83h20.46v20.46h-20.46zM77.31 97.83h20.46v20.46H77.31zM55.34 97.83H75.8v20.46H55.34zM452.91 34.25C429.24 15 352.16 14.91 354 56.84c-1.3 40.11 62.85 40.14 86.98 22.74-1.65 19.42-15.63 24.7-33.61 24.14-16.02-.12-30.25-1.51-46.57-2.8v19.06c26.5 1.32 61.35 9.96 85.22-3.82 26.43-14.24 27.7-64.34 6.88-81.9m-11.81 28.4c-.41 14.54-44.52 15.43-54.76 8.58-9.26-4.87-9.21-19.72 0-24.64 12.85-6.38 60.2-9.38 54.76 16.06"
        className="cls-5"
      />
    </svg>
  );
}

export function LogoArWhite({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      viewBox="0 0 657.63 199.34"
      className="min-h-[64px] min-w-[210px]"
    >
      <defs>
        <style>{".cls-9{fill:#fff}"}</style>
      </defs>
      <path
        d="M576.96 172.88c3.58 3.37-24.22.52-23.29.3-1.45-.37-.34-1.61.54-2 2.27-1.27 4.55-2.58 6.86-3.92 1.42-.87 3.18-1.3 4.77-.63.52.2 1.03.48 1.51.77 3.19 1.97 6.44 3.63 9.58 5.44l.04.03ZM649.63 103.65c-.25 1.98-.43 4.32-.95 6.3-.72 2.62-2.26 2.69-4.43 1.29-1.14-.69-2.64-1.32-3.2-2.6-.39-1.05.51-2.06 1.24-2.75 1.2-1.1 2.38-2.17 3.57-3.26 1.39-1.45 3.62-1.35 3.76.95v.06ZM500.12 93.08c-1.66.98-3.58 1.51-5.35 2.29-1.09.43-2.17.86-3.26 1.28-1.25.39-2.66 1.33-3.94 1.01-.74-.21-1.06-.98-1.04-1.66-.28-4.99.86-28.37 5.17-25.58 1.13.85 1.38 2.58 1.92 3.87.45 1.25.92 2.5 1.41 3.77.78 2.03 1.62 4.08 2.51 6.15.87 2.02 1.79 4.05 2.76 6.1.43.87.82 2.05-.11 2.73l-.05.04ZM564.45 59.05c-4.25 2.76-8.69 5.29-13.08 7.86-1.77 1.12-4.01 1.91-6.01.93-1.33-.57-2.27-1.81-2.95-3.06-6.69-12.33-12.58-25.27-16.34-38.89-.34-2.32 1.05-4.43 3.07-5.47 4.69-2.6 9.66-4.71 14.85-6.34 3.71-1.32 6.8.39 7.78 4.18 3.61 11.68 8.51 22.95 14.51 34.08 1.16 2.33.4 5.23-1.77 6.67l-.05.04ZM609.45 21.35c5.06 3.85-1.68 7.86-4.73 10.13-3.96 2.96-7.94 6.01-12.07 8.76-2.92 1.9-6.76 6.2-10.49 5.8-1.53-.21-2.61-1.81-3.37-3.1-3.45-5.69-6.64-11.58-9.52-17.62-.55-1.18-.97-2.2-1.58-3.43-1.25-2.76-3.41-6.04-2.68-9.08.6-2.06 3.43-2.53 5.35-2.42 13.72.37 27.32 3.89 39.03 10.92l.06.05ZM629.86 118.74c-20.35 18.65-29.81 13.54-2.24 27.63 5.02 2.17 12.76-14.59 15.17-18.86 1.85-3.47-1.47-4.39-3.98-5.69-1.42-.72-2.85-1.48-4.26-2.24-1.44-.79-3.11-1.89-4.65-.87l-.05.03ZM490.66 118.15c1.53 4.63 3.91 10.32 6.37 14.88 2.09 3.61 3.99 6.39 6.29 6.14.59-.1 1.33-.45 1.95-.72 5.11-2.36 10.29-4.61 15.27-7.24 1.91-1.14 1.91-3.24.58-4.85-3.52-4.54-6.82-9.18-9.74-13.81-1.76-3.21-4.75-4.5-8.14-2.81-3.22 1.51-6.43 3-9.67 4.45-1.72.66-3.31 1.91-2.91 3.91v.05ZM638.37 49.18c-3.6-5.4-12.93-20.77-19.12-16.4-9.3 8.09-18.86 15.96-28.6 23.57-1.63 1.6-.83 3.24.53 4.67 4.08 5.11 11.25 15.15 15.13 16.28 1 .35 1.87-.59 2.73-1.23 9.65-7.46 19.09-15.24 28.16-23.34 1.01-.82 1.78-2.29 1.2-3.5l-.02-.05Z"
        className="cls-9"
      />
      <path
        d="M649.86 81.07c.84 5.75-2.61 10.58-6.68 14.23-3.08 2.78-6.26 5.35-9.23 8.18-9.34 8.15-18.87 16.15-28.84 23.52-3.19 2.15-6.97 1.44-9.73-1.03-7.01-5.56-15.03-12.1-21.13-18.58-1.16-1.19-2.52-2.67-4.22-2.89-1.42-.18-2.79.74-3.96 1.47-8.04 5-16.18 9.8-24.47 14.34-1.07.58-2.21 1.11-3.41 1.33-2.7.51-5.48-.39-7.39-2.37-14.57-16.97-25.76-36.52-33.79-57.41-.68-1.7-1.2-3.44-1.02-5.25.65-7.78 9.46-16.52 14.53-22.12 1.3-1.48 3.69-1.96 5.13-.4.71.73 1.05 1.77 1.33 2.75 1.32 4.61 2.87 9.37 4.68 14.2 4.5 11.62 10.14 24.06 17.7 34.64 2.12 2.22 5.4 2.08 7.83.42 5.08-3.09 8.46-5.3 12.89-8.25 20.68-14.7 15.2-8.32 32.16 9.15a179.8 179.8 0 0 0 5.98 6.19c.92.9 2.16 1.65 3.47 1.49 1.22-.13 2.13-.81 2.99-1.54 7.36-6.3 21.92-19.16 28.11-25.05.68-.64 1.23-1.09 1.99-1.83 2.2-2.16 5.27-6.31 7.7-6.26 1.44.02 2.28 1.68 2.75 2.91 2.21 5.75 3.84 11.87 4.65 18.1v.07Z"
        className="cls-9"
      />
      <path
        d="M617.64 158.2c-5.95 4.13-15.38 10.76-21.78 11.05-1.2.08-2.37-.35-3.47-.94-14.8-7.75-30.33-17.71-42.84-29.85-1.93-2.85-.44-5.94 2.39-7.43 4.37-2.72 8.68-5.51 12.96-8.37 1.25-.87 2.76-1.58 4.32-1.31.89.13 1.71.55 2.45 1.11 13.57 11.47 29.06 20.83 44.68 27.48 3.39 1.4 4.24 5.84 1.34 8.22l-.05.04ZM550.78 163.01c-3.68 1.84-8.16 5.94-12.07 5.7-1.15-.15-1.94-.69-3.03-1.15-.8-.36-1.72-.79-2.71-1.26-7.97-3.9-23.2-11.48-13.47-16.73 4.34-2.17 8.47-4.75 12.85-6.81 2.02-.78 3.95.42 5.31 1.87 4.11 3.94 8.29 7.56 12.51 10.89 2.81 1.91 3.7 5.28.67 7.46l-.05.04ZM211.55 182.72c-4.7-.04-8.04-.8-10.03-2.27-3.61-2.6-5.44-6.08-5.48-10.43 0-4.43 1.65-7.97 4.95-10.61 2.29-1.75 4.9-2.62 7.81-2.62 3.58 0 6.68.89 9.33 2.68v19.47h4.2v3.79h-10.78Zm-11.75-32.73c-.52-.52-.79-1.16-.79-1.89s.26-1.38.79-1.92c.52-.54 1.16-.82 1.89-.82s1.37.26 1.89.79c.52.52.79 1.18.79 1.95s-.26 1.37-.79 1.89-1.16.79-1.89.79-1.37-.26-1.89-.79Zm13.96 11.17c-1.83-.7-3.58-1.05-5.25-1.05-2.1 0-3.85.72-5.25 2.16-1.94 2.1-2.91 4.57-2.91 7.4 0 6.14 3.5 9.23 10.49 9.27h2.91v-17.78Zm-4.95-11.17c-.54-.52-.82-1.16-.82-1.89s.27-1.38.82-1.92 1.19-.82 1.92-.82c.7 0 1.31.26 1.84.79.52.52.79 1.18.79 1.95s-.26 1.37-.79 1.89c-.52.52-1.14.79-1.84.79-.74 0-1.38-.26-1.92-.79Z"
        className="cls-9"
      />
      <path
        d="M221.46 182.72v-3.79h6.06c.35-1.36.52-3.34.52-5.95v-11.6h4.02v7.11c1.59-2.99 3.92-5.52 6.99-7.58 2.56-1.71 5.23-2.56 7.99-2.56 4.12 0 7.5 1.81 10.14 5.42 2.1 2.99 3.15 6.99 3.15 12.01v6.94h-38.88Zm34.56-7.17c0-4-.5-6.82-1.52-8.45-2.06-3.15-4.68-4.74-7.87-4.78-3.03 0-6.02 1.67-8.98 5.01-2.99 3.23-5.03 7.09-6.12 11.6h24.48v-3.38Zm-20.25-23.81a2.6 2.6 0 0 1-.79-1.89c0-.78.26-1.43.79-1.95s1.16-.79 1.89-.79 1.37.26 1.89.79c.52.52.79 1.18.79 1.95s-.26 1.37-.79 1.89c-.52.52-1.16.79-1.89.79s-1.37-.26-1.89-.79ZM273.21 194.91v-3.85h2.56c4.7 0 7.19-2.78 7.46-8.34h-2.74c-4.7-.04-8.02-.8-9.97-2.27-3.65-2.6-5.5-6.08-5.54-10.43 0-4.43 1.67-7.97 5.01-10.61 2.22-1.75 4.8-2.62 7.75-2.62 3.58 0 6.68.91 9.33 2.74v19.41h4.2v3.79h-4.2c0 8.08-3.65 12.14-10.96 12.18h-2.91Zm9.56-33.75c-1.83-.7-3.59-1.05-5.3-1.05-2.06 0-3.81.72-5.25 2.16-1.94 2.06-2.91 4.53-2.91 7.4 0 6.14 3.52 9.23 10.55 9.27h2.91v-17.78Z"
        className="cls-9"
      />
      <path
        d="M297.64 179.4c-1.05 2.21-2.99 3.32-5.83 3.32h-1.4v-3.79h.87c3.19-.04 4.82-2.33 4.9-6.88v-.99c0-4.2.92-7.42 2.77-9.68 1.85-2.25 4.58-3.38 8.19-3.38s6.33 1.19 8.39 3.56c2.06 2.37 3.09 5.75 3.09 10.14 0 3.3-.56 6.02-1.69 8.16-1.79 3.46-4.84 5.23-9.15 5.3-4.55 0-7.93-1.92-10.14-5.77Zm16.78-7.05c0-7.23-2.35-10.84-7.05-10.84-4.51.08-6.76 3.42-6.76 10.03s2.35 10.14 7.05 10.14 6.76-3.11 6.76-9.33ZM337.27 184.65v-5.71h8.16l-6.94-13.64c2.06-3.58 5.09-6.12 9.09-7.64 1.83-.62 3.52-.93 5.07-.93 1.2 0 2.43.19 3.67.58 3.34 1.09 5.77 3.19 7.29 6.3.85 1.83 1.28 3.65 1.28 5.48 0 3.96-1.48 7.25-4.43 9.85h8.69v3.79h-27.8v1.28c0 4.86 2.56 7.29 7.69 7.29 3.26-.04 5.81-.33 7.64-.87v3.79c-1.98.39-4.76.62-8.34.7-7.31 0-11-3.42-11.07-10.26Zm20.23-8.4c2.06-2.02 3.09-4.35 3.09-6.99-.08-4.16-1.9-6.84-5.48-8.04-.85-.31-1.67-.47-2.45-.47-4.04 0-7.19 1.61-9.44 4.84l6.94 13.35c3.42-.27 5.87-1.17 7.34-2.68Z"
        className="cls-9"
      />
      <path
        d="M368.28 182.72v-3.79h6v-19h4.31v19h6v3.79h-16.32Zm6.09-30.98a2.6 2.6 0 0 1-.79-1.89c0-.78.26-1.43.79-1.95s1.16-.79 1.89-.79 1.37.26 1.89.79c.52.52.79 1.18.79 1.95s-.26 1.37-.79 1.89c-.52.52-1.16.79-1.89.79s-1.37-.26-1.89-.79Z"
        className="cls-9"
      />
      <path
        d="M383.73 182.72v-3.79h6.06c.35-1.36.52-3.34.52-5.95v-11.6h4.02v7.11c1.59-2.99 3.92-5.52 6.99-7.58 2.56-1.71 5.23-2.56 7.99-2.56 4.12 0 7.5 1.81 10.14 5.42 2.1 2.99 3.15 6.99 3.15 12.01v6.94h-38.88Zm34.56-7.17c0-4-.5-6.82-1.52-8.45-2.06-3.15-4.68-4.74-7.87-4.78-3.03 0-6.02 1.67-8.98 5.01-2.99 3.23-5.03 7.09-6.12 11.6h24.48v-3.38ZM425.81 196.07a8.16 8.16 0 0 1 2.16-1.28c-1.2-.82-1.81-1.9-1.81-3.26 0-1.52.62-2.64 1.87-3.38.66-.39 1.4-.58 2.21-.58.93 0 1.75.17 2.45.52l-.35 1.98c-.51-.16-.89-.23-1.17-.23-1.4 0-2.1.64-2.1 1.92 0 1.48 1.34 2.21 4.02 2.21h.41l-.06 2.04c-2.56.39-4.99 1.15-7.29 2.27l-.35-2.21Zm1.46-13.35v-36.49h4.31v36.49h-4.31ZM317.41 59.89c-.51 9.03.51 15.17-6.42 18.78-6.28 2.35-18.22 1.28-25.81.34V35.14h-24.29c-.62 9.55 1.47 64.4-1.41 72.18-4.1 11.28-23.72 6.03-33.75 5.58v19.49c30.47 4.81 59.68 5.3 59.4-33.75 57.86 8.69 57.69-14.34 56.57-63.5h-24.29V59.9Z"
        className="cls-9"
      />
      <path
        d="M246.33 82.98c4.54-10.88 2.8-35.22 3.11-47.85h-24.29v24.76c-.04 7.78-.17 15.35-8.27 17.65-10.47 3.02-28.36 2.8-34.22-4.66-9.13-8.66-4.75-40.84-5.72-53.12h-24.29c1.29 18.33-6.3 61.38 11.86 71.83 23.63 13.72 74.92 14.77 81.81-8.62M133.83 38.3c-11.98-8.12-37.31-8.21-48.54 2.13-7.24 6.51-8.25 18.65-7.82 28.33-.13 4.01-1.13 9.36-2.7 12.03-4.05 8.66-18.71 8.28-22.75.24-4.95-7.91-2.59-25.02-3.12-34.15H24.34c-1.8 44.62 3.08 56.83 50.4 53.68 14.98-1.59 23.4-7.72 26.24-21.8 1.93-7.49-1.22-20.55 5.15-25.46 2.52-2.32 6.69-2.43 9.27-.15 7.84 5.54 3.08 39.24 4.31 47.74h24.56c-1.39-16.22 5.43-52.91-10.43-62.59M312.73 108.2h20.46v20.46h-20.46zM190.68 108.2h20.46v20.46h-20.46zM290.77 108.2h20.46v20.46h-20.46zM69.66 108.2h20.46v20.46H69.66zM47.7 108.2h20.46v20.46H47.7zM445.27 44.62c-23.67-19.25-100.75-19.34-98.91 22.59-1.3 40.11 62.85 40.14 86.98 22.74-1.65 19.42-15.63 24.7-33.61 24.14-16.02-.12-30.25-1.51-46.57-2.8v19.06c26.5 1.32 61.35 9.96 85.22-3.82 26.43-14.24 27.7-64.34 6.88-81.9m-11.81 28.4c-.41 14.54-44.52 15.43-54.76 8.58-9.26-4.87-9.21-19.72 0-24.64 12.85-6.38 60.2-9.38 54.76 16.06"
        className="cls-9"
      />
    </svg>
  );
}
