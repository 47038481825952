export default function TikTok() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4223 19.6585C12.1544 20.1138 7.85014 20.1138 3.58221 19.6585C2.75342 19.571 1.97967 19.202 1.38995 18.6131C0.800232 18.0242 0.430185 17.251 0.341501 16.4223C-0.113834 12.1544 -0.113834 7.85014 0.341501 3.58221C0.429022 2.75342 0.797984 1.97967 1.38687 1.38995C1.97576 0.800232 2.749 0.430185 3.57767 0.341501C7.8456 -0.113834 12.1499 -0.113834 16.4178 0.341501C17.2466 0.429022 18.0203 0.797984 18.6101 1.38687C19.1998 1.97576 19.5698 2.749 19.6585 3.57767C20.1138 7.8456 20.1138 12.1499 19.6585 16.4178C19.571 17.2466 19.202 18.0203 18.6131 18.6101C18.0242 19.1998 17.251 19.5698 16.4223 19.6585Z"
        fill="white"
      />
      <path
        d="M8.49199 16.2495C7.43421 16.2495 6.41974 15.8293 5.67177 15.0813C4.92381 14.3333 4.5036 13.3189 4.5036 12.2611C4.5036 11.2033 4.92381 10.1888 5.67177 9.44087C6.41974 8.6929 7.43421 8.27269 8.49199 8.27269H9.4658V10.2192H8.49199C8.08747 10.2192 7.69202 10.3391 7.35567 10.5639C7.01932 10.7886 6.75716 11.108 6.60236 11.4818C6.44755 11.8555 6.40705 12.2668 6.48596 12.6635C6.56488 13.0603 6.75968 13.4247 7.04573 13.7108C7.33177 13.9968 7.69621 14.1916 8.09297 14.2705C8.48972 14.3494 8.90097 14.3089 9.27471 14.1541C9.64844 13.9993 9.96788 13.7372 10.1926 13.4008C10.4174 13.0645 10.5373 12.669 10.5373 12.2645V3.75024H12.4838V4.72291C12.4838 5.26537 12.6993 5.7856 13.0829 6.16918C13.4664 6.55275 13.9867 6.76824 14.5291 6.76824H15.5029V8.7113H14.5223C13.8014 8.71234 13.094 8.51585 12.477 8.14316V12.2611C12.4761 13.318 12.0561 14.3314 11.309 15.0791C10.562 15.8268 9.54891 16.2477 8.49199 16.2495Z"
        fill="#302E56"
      />
    </svg>
  );
}
