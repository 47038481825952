import {
  Link,
  NavLink,
  useFetcher,
  useLoaderData,
  useLocation,
} from "@remix-run/react";
import { Menu, X } from "lucide-react";
import { useRef, useState } from "react";
import Logo from "~/svgs/logo/Logo";
import { cn, languages } from "~/utils/helper";
import DownloadAppBtn from "./DownloadAppBtn";
import { useTranslation } from "react-i18next";
import { loader } from "~/routes/_/route";
import { ActionTypes } from "~/root";
import clsx from "clsx";
import { LogoAr } from "~/svgs/logo/LogoAr";
import { useClickOutside } from "~/utils/hooks/useClickOutside";
import { AllId } from "~/utils/data/categories";

export default function Nav() {
  const [navOpen, setNavOpen] = useState(false);

  // Getting current language code from cookies
  const userPrefs = useLoaderData<typeof loader>();

  // Getting other language to show in the nav
  const askForLang =
    Object.keys(languages).find((l) => l !== userPrefs?.locale) ||
    Object.keys(languages)[0];

  const links = [
    { href: "/about", text: "aboutUs" },
    {
      href: "/services",
      text: "services",
      subRoutes: [
        { href: "/services/sourcing", text: "sourcing" },
        { href: "/services/design", text: "design" },
        { href: "/services/production", text: "production" },
      ],
    },
    { href: "/how-it-works", text: "how it works" },
    { href: "/blogs/" + AllId, text: "blog" },
  ];

  let { t } = useTranslation();

  const fetcher = useFetcher();
  const location = useLocation();

  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => setNavOpen(false));

  return (
    <nav className="z-50 h-20 bg-white shadow">
      <div className="cont flex h-full min-w-full items-center justify-between  gap-8 bg-inherit lg:gap-16">
        {/* Logo  */}
        <Link to="/">{userPrefs?.locale === "ar" ? <LogoAr /> : <Logo />}</Link>
        <div
          className={`fixed right-0 top-0 z-50 flex h-screen flex-col items-center gap-6 overflow-y-scroll bg-inherit px-4 py-8 shadow-md transition-all lmd:static lmd:contents ${!navOpen && "translate-x-full "}`}
          ref={ref}
        >
          <div
            onClick={() => setNavOpen(false)}
            className="self-start  lmd:hidden"
          >
            <X className="min-h-6 " />
          </div>
          <ul
            onClick={() => setNavOpen(false)}
            className="z-50 flex max-w-[630px] flex-1 flex-col items-center  gap-3 max-lmd:w-full max-lmd:gap-0 max-lmd:divide-y lmd:flex-row lmd:justify-center lg:gap-4 xl:gap-10"
          >
            {links.map((link) => (
              <li
                key={link.href}
                className="group relative flex flex-col self-stretch font-semibold capitalize lmd:py-4"
              >
                <NavLink
                  className={({ isActive }) => {
                    const myActive =
                      link.subRoutes?.some(
                        (subRoute) => subRoute.href === location.pathname,
                      ) ?? isActive;
                    return cn("relative whitespace-nowrap max-lmd:py-4", {
                      "text-Primary-Magic-Mint-Mm-600 before:absolute before:-bottom-2 before:left-1/2 before:size-1 before:-translate-x-1/2 before:rounded-full lg:before:bg-Primary-Magic-Mint-Mm-600 ":
                        myActive,
                    });
                  }}
                  to={
                    link.subRoutes?.length ? link.subRoutes[0].href : link.href
                  }
                >
                  {t(link.text)}
                </NavLink>{" "}
                {/* Sub Routes */}
                {link.subRoutes && (
                  <div
                    className={`top-12 hidden w-[220px] flex-col divide-y rounded-lg border bg-white hover:flex group-hover:flex max-lmd:mb-4 max-lmd:flex lmd:absolute`}
                  >
                    {link.subRoutes.map((subRoute) => (
                      <NavLink
                        key={subRoute.href}
                        className={({ isActive }) =>
                          cn(
                            " whitespace-nowrap p-4 transition-colors hover:bg-[#DFE4F5]",
                            {
                              "bg-[#DFE4F5]": isActive,
                            },
                          )
                        }
                        to={subRoute.href}
                      >
                        {t(subRoute.text)}
                      </NavLink>
                    ))}
                  </div>
                )}
              </li>
            ))}
          </ul>

          {/* Switch Language Button */}
          <div className="flex items-center gap-5 max-lmd:flex-col lg:gap-10">
            <fetcher.Form method="post">
              <input
                type="hidden"
                name="_action"
                value={ActionTypes.SET_LOCALE}
              />
              <button
                type="submit"
                className="flex cursor-pointer items-center gap-1 rounded-lg border border-Secondary-Dark-Blue-db-50 px-3 py-[6px]"
              >
                <input type="hidden" name="locale" value={askForLang} />

                <span>{languages[askForLang].name}</span>
                <span>{languages[askForLang].icon}</span>
              </button>
            </fetcher.Form>
            <DownloadAppBtn className="z-50 ml-auto" />
          </div>
        </div>
        <Menu className="lmd:hidden" onClick={() => setNavOpen(true)} />
      </div>
    </nav>
  );
}
