export default function Phone() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="min-h-5 min-w-5"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.33679 0.581184C2.90196 1.38702 3.50245 2.19664 4.13827 3.01004C4.77186 3.82077 5.43737 4.63253 6.1348 5.44533L6.71885 4.86128C6.91719 4.66447 7.03323 4.39957 7.04342 4.12034C7.05361 3.84111 6.95719 3.56846 6.77373 3.35771L4.20358 0.387907C4.10543 0.271402 3.98395 0.176754 3.84698 0.110061C3.71002 0.0433687 3.5606 0.00611156 3.40835 0.000690709C3.25611 -0.00473014 3.10442 0.0218055 2.96306 0.0785877C2.82169 0.13537 2.6938 0.221139 2.5876 0.330368L2.33679 0.581184ZM14.5555 13.8662C15.3663 14.5616 16.1781 15.227 16.9908 15.8625C17.8034 16.4981 18.613 17.0986 19.4196 17.6642L19.6707 17.4132C19.7799 17.307 19.8657 17.1791 19.9225 17.0378C19.9792 16.8964 20.0058 16.7447 20.0003 16.5925C19.9949 16.4403 19.9576 16.2909 19.8909 16.1539C19.8241 16.017 19.7294 15.8955 19.6129 15.7974L16.6422 13.2262C16.4315 13.0427 16.1588 12.9463 15.8796 12.9565C15.6003 12.9667 15.3354 13.0827 15.1386 13.2811L14.5546 13.8651L14.5555 13.8662ZM18.942 18.1399L17.7452 19.3366C17.3907 19.7047 16.913 19.9293 16.4034 19.9676C15.9013 20.0123 15.3961 20.0108 14.8943 19.9629C11.6365 19.6625 8.07557 17.5803 5.2475 14.7522C2.41943 11.9241 0.337366 8.36338 0.0370082 5.10565C-0.0107252 4.60382 -0.0122869 4.09866 0.0323429 3.59653C0.0706382 3.08693 0.295235 2.60926 0.663271 2.2547L1.86004 1.05793C2.41054 1.83934 2.99556 2.62622 3.61508 3.41858C4.26808 4.25434 4.95092 5.08692 5.6636 5.91631L5.05533 6.5248C4.84423 6.73165 4.6894 6.98896 4.6055 7.27236C4.52161 7.55577 4.51143 7.85589 4.57592 8.14433C4.9875 9.9153 5.89729 11.5315 7.19782 12.8021C8.46846 14.1025 10.0847 15.0121 11.8556 15.4236C12.144 15.488 12.4442 15.4779 12.7276 15.394C13.011 15.3101 13.2683 15.1552 13.4751 14.9441L14.0847 14.3363C14.912 15.0472 15.7445 15.73 16.5822 16.3846C17.3722 17.0028 18.1588 17.5879 18.942 18.1399Z"
        fill="white"
      />
    </svg>
  );
}
