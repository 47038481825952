import { Link, useLoaderData } from "@remix-run/react";
import Facebook from "~/svgs/Facebook";
import Insta from "~/svgs/Insta";
import LinkedIn from "~/svgs/LinkedIn";
import Logo, { LogoLg } from "~/svgs/logo/Logo";
import TikTok from "~/svgs/TikTok";
import DownloadAppBtn from "./DownloadAppBtn";
import { useTranslation } from "react-i18next";
import { loader } from "~/routes/_/route";
import { LogoArWhite } from "~/svgs/logo/LogoAr";
import { AllId } from "~/utils/data/categories";
import X from "~/svgs/footer/X";
import Mail from "~/svgs/footer/Mail";
import Phone from "~/svgs/footer/Phone";

export default function Footer() {
  const { t } = useTranslation();
  const locale = useLoaderData<typeof loader>()?.locale;

  const linkGroups = [
    {
      title: "company",
      links: [
        { text: "aboutUs", href: "/about" },
        { text: "blog", href: "/blogs/" + AllId },
        { text: "how it works", href: "/how-it-works" },
        { text: "faq", href: "/faq" },
      ],
    },
    {
      title: "services",
      links: [
        { text: "sourcing", href: "/services/sourcing" },
        { text: "design", href: "/services/design" },
        { text: "production", href: "/services/production" },
      ],
    },
    {
      title: "workWithUs",
      links: [
        { text: "designers", href: "/segments/designers" },
        { text: "cloudWorkshops", href: "/segments/workshops" },
        { text: "artisans", href: "/segments/artisans" },
      ],
    },
    {
      title: "polices",
      links: [
        { text: "terms & conditions", href: "/terms" },
        { text: "orders policy", href: "/order-policy" },
        { text: "privacy policy", href: "/privacy-policy" },
      ],
    },
  ];

  return (
    <footer className="relative z-10 bg-Secondary-Dark-Blue-db-500 font-semibold text-white">
      <div className="cont grid min-w-full auto-cols-auto gap-x-7  gap-y-10  px-4 py-7 sm:px-16  md:grid-flow-col md:grid-rows-3 lg:grid-rows-1 lg:justify-items-end lg:px-[120px] lg:py-14">
        <div className="col-span-2 flex flex-col items-start gap-6 justify-self-start md:col-span-1">
          <Link to="/">{locale === "en" ? <LogoLg /> : <LogoArWhite />}</Link>
          <p className="whitespace-nowrap text-sm font-normal sm:max-w-[200px]">
            {t("availableOn")}
          </p>
          <DownloadAppBtn className="bg-white text-base text-Secondary-Dark-Blue-db-500" />
        </div>
        <div className="contents">
          {linkGroups.map((group, i) => (
            <div key={i} className="space-y-4">
              <h3 className="text-lg font-bold">{t(group.title)}</h3>
              <ul className="space-y-4">
                {group.links.map((link, j) => (
                  <li key={j}>
                    <a
                      className="text-sm font-normal text-[#C8C5DA]"
                      href={link.href}
                    >
                      {t(link.text)}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="w-32">
          <div className="flex flex-col ">
            <h3 className="text-lg">{t("follow")}</h3>
            <div className="mt-3 flex gap-3">
              <a
                href="https://www.linkedin.com/company/wearby/mycompany/"
                target="_blank"
              >
                <LinkedIn />
              </a>
              <a href="https://www.facebook.com/wearbyapp" target="_blank">
                <Facebook />
              </a>
              <a href="" target="_blank">
                <Insta />
              </a>
              <a href="" target="_blank">
                <TikTok />
              </a>
              <a href="" target="_blank">
                <X />
              </a>
            </div>
          </div>
          <div className=" mt-6 flex flex-col">
            <h3 className="text-lg">{t("sendMail")}</h3>
            <div className="mt-3 flex flex-col gap-3 text-sm font-normal">
              <a
                href="mailto:info@wearby.app"
                className="flex items-center gap-2"
              >
                <Mail />
                <span>info@wearby.app</span>
              </a>
              <a href="tel:966565455634" className="flex items-center gap-2">
                <Phone />
                <span dir="ltr">+966565455634</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center bg-Secondary-Dark-Blue-db-600 px-4 py-4 text-sm">
        <p className="mx-5">{t("allRightsReserved")}</p>
      </div>
    </footer>
  );
}
